export const lisa_delong_works = {
  name: "Lisa DeLong",
  workPromo: {
    image: require("./Hope.jpeg"),
    heroDetails: {
      name: "Lisa DeLong",
      title: "Hope"
    }
  },
  link: "/Lisa_DeLong",
  works: [
    {
      image: require("./Hope.jpeg"),
      details: {
        title: "Hope",
        materials: "Watercolour and bronze powder on papyrus",
        isForSale: true
      },
    },
    {
      image: require("./Lisa DeLong - Cloth of Honour.jpeg"),
      details: {
        title: "Cloth of Honor",
        materials: "Gold leaf, ink, watercolour, and collage on red paper",
        size: "19 x 52 cm",
        isForSale: true
      },
    },
    {
      image: require("./Malachite Medley.JPG"),
      details: {
        title: "Malachite Medley",
        materials: "Handmade watercolors and malachite on Khadi paper",
        isForSale: true
      },
    },
    {
      image: require("./One Minute More.jpeg"),
      details: {
        title: "One Minute More",
        materials: "Handmade watercolour and ink on marbled paper",
        size: "22cm x 22cm",
        isForSale: true
      },
    },
    {
      image: require("./Tangled up in Blue.jpeg"),
      details: {
        title: "Tangled Up in Blue",
        materials:
          "Watercolour and handmade chrysocholla paint on Nideggan paper",
        size: "55 x 55 cm",
        isForSale: true
      },
    },
    {
      image: require("./Web of Life.JPG"),
      details: {
        title: "Web of Life",
        materials: "Handmade watercolour on handmade paper",
        isForSale: true
      },
    }
  ],
  artistPhoto: require("./IMG_1218.jpg"),
  bio: `Lisa DeLong is an artist and educator who currently works for The Prince's School of Traditional Arts in London, designing curricula and conducting art educational programs, teacher training, and product development workshops.  She has taught courses in the art of geometry, symbolism, and traditional design in many parts of the world including Saudi Arabia, Abu Dhabi, Qatar, Syria, Egypt, Malaysia, and Azerbaijan. \n \n Lisa received her PhD in Traditional Art from The Prince's School in 2007. Her paintings are in several important collections, including that of HH President Sheikh Khalifa bin Zayed al Nahyan and HRH The Prince of Wales and she completed a commission for HM Queen Elizabeth II. She is also the author of the book Curves: Flowers, Foliates, and Flourishes in the Formal Decorative Arts.`
};
