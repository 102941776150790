export const morgan_mckenna_works = {
  name: "Morgan McKenna",
  workPromo: {
    image: require("./McKenna Venus.jpg"),
    heroDetails: {
      name: "Morgan McKenna",
      title: "Venus",
      materials: "Photography"
    }
  },
  link: "Morgan_McKenna",
  works: [
    {
      image: require("./McKenna Blue Grid 7.jpg"),
      details: {
        title: "Blue Grid 7",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./Mckenna Drawn In.jpg"),
      details: {
        title: "Drawn In",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Duality.jpg"),
      details: {
        title: "Duality",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Finisterre.jpg"),
      details: {
        title: "Finisterre",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Illumine.jpg"),
      details: {
        title: "Illumine",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Inland Passage.jpg"),
      details: {
        title: "Inland Passage",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Last Stand.jpg"),
      details: {
        title: "Last Stand",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Layers.jpg"),
      details: {
        title: "Layers",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Liquid Autumn.jpg"),
      details: {
        title: "Liquid Autumn",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Lotus.jpg"),
      details: {
        title: "Lotus",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKEnna Midas Beach.jpg"),
      details: {
        title: "Midas Beach",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Millennia.jpg"),
      details: {
        title: "Millennia",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Montage.jpg"),
      details: {
        title: "Montage",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Orange Rain.jpg"),
      details: {
        title: "Orange Rain",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Patina.jpg"),
      details: {
        title: "Patina",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Plaid.jpg"),
      details: {
        title: "Plaid",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Porcelain.jpg"),
      details: {
        title: "Porcelain",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Primeval.jpg"),
      details: {
        title: "Primeval",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Rain on Maples 1.jpg"),
      details: {
        title: "Rain on Maples 1",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Reverie.jpg"),
      details: {
        title: "Reverie",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Ritual.jpg"),
      details: {
        title: "Ritual",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Tiffany .jpg"),
      details: {
        title: "Tiffany",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Sarabande.jpg"),
      details: {
        title: "Sarabande",
        materials: "Photography",
        isForSale: true
      }
    },
    {
      image: require("./McKenna Venus.jpg"),
      details: {
        title: "Venus",
        materials: "Photography",
        isForSale: true
      }
    }
  ],
  artistPhoto: require("./Morgan Portrait Photographer.jpg"),
  bio: `Morgan Leigh McKenna has been photographing nature since her childhood in the Rocky Mountain west. \n \n In the words of one of her longtime clients, “There is a deep knowing and mystery in McKenna’s work. Her intimate renditions of flowers, botanicals, and water evoke wonder and a healing presence in a world that needs more of both."`
};
