export const trudie_canwood_works = {
  name: "Trudie Canwood",
  workPromo: {
    image: require("./2019-06 100x80.jpg"),
    heroDetails: {
      name: "Trudie Canwood",
      title: "2019-06",
      materials: "Acrylic on canvas"
    }
  },
  link: "/Trudie_Canwood",
  works: [
    {
      image: require("./2018-08 50x50.jpg"),
      details: {
        title: "1808",
        materials: "Acrylic on canvas",
        size: "50x50cm",
        isForSale: true
      }
    },
    {
      image: require("./2018-10 50x50.jpg"),
      details: {
        title: "1810",
        materials: "Acrylic on canvas",
        size: "50x50cm",
        isForSale: true
      }
    },
    {
      image: require("./2019-02 100x120.jpg"),
      details: {
        title: "1902",
        materials: "Acrylic on canvas",
        size: "100x120cm",
        isForSale: true
      }
    },
    {
      image: require("./2020-30 ontsnappen aan....jpg"),
      details: {
        title: "2030",
        materials: "Acrylic on canvas",
        size: "50x60cm",
        isForSale: true
      }
    },
    {
      image: require("./2021-11.jpg"),
      details: {
        title: "2111",
        materials: "Acrylic on canvas",
        size: "60x70cm",
        isForSale: true
      }
    },
    {
      image: require("./2020-18 kleiner.jpg"),
      details: {
        title: "2020-18",
        materials: "Acrylic on canvas",
        size: "50x50cm",
        isForSale: true
      }
    },
    {
      image: require("./2020-19.jpg"),
      details: {
        title: "2020-19",
        materials: "acrylic on canvas",
        size: "15x15cm",
        isForSale: true
      }
    },
    {
      image: require("./2020-23 kleiner.jpg"),
      details: {
        title: "2020-23",
        materials: "acrylic on canvas",
        size: "50 x 50cm",
        isForSale: true
      }
    },
    {
      image: require("./2020-26 wit rood 50x50 kleiner.jpg"),
      details: {
        title: "2020-26",
        materials: "acrylic on canvas",
        size: "50 x 50cm",
        isForSale: true
      }
    },
    {
      image: require("./2020-27 wit blauw 60x60.jpg"),
      details: {
        title: "2020-27",
        materials: "acrylic on canvas",
        size: "60 x 60cm",
        isForSale: true
      }
    }
  ],
  artistPhoto: require("./foto Trudie.jpg"),
  bio: `Trudie Canwood-Kruger (Arnhem, The Netherlands, 1961) is a professional artist.  In 2003, after spending a month on the beautiful caribbean island of Curaçao, she started painting. This work, in bright effulgent colours and inspired on her mother-in-law and daily caribbean life, had an unmistakable carribbean feel. Nowadays she mainly paints landscapes in a completely different style inspired on (childhood) memories, trips and holidays. \n \n “When I start painting these landscapes, most of the time I have no idea where I’m going. The work grows along the way and it’s always a struggle to decide when it’s finished”. Canwood had exhibitions in New York, London, Amsterdam, Copenhagen, Aruba, Nevis and more. In 2011 for the first time a solo-exhibition was completely sold out.`
};
