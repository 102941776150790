export const dorothy_boyer_works = {
  name: "Dorothy F. Boyer",
  workPromo: {
    image: require("./Market Day Arabia.jpg"),
    heroDetails: {
      name: "Dorothy F. Boyer",
      title: "Market Day Arabia"
    }
  },
  link: "/Dorothy_Boyer",
  works: [
    {
      image: require("./A Little Bit of Yellow.jpg"),
      details: {
        title: "A Little Bit of Yellow",
        materials: "Watercolor on Paper",
        size: "15.25 in x 17 in",
        isForSale: true
      }
    },
    {
      image: require("./All the Blues 2.jpg"),
      details: {
        title: "All the Blues 2",
        materials: "Watercolor on Paper",
        size: "13.5 in x 17.5 in",
        isForSale: true
      }
    },
    {
      image: require("./All the Reds.jpg"),
      details: {
        title: "All the Reds",
        materials: "Watercolor on Paper",
        size: "13 in x 17 in",
        isForSale: true
      }
    },
    {
      image: require("./Baroque Fountain Granada.jpg"),
      details: {
        title: "Baroque Fountain Granada",
        materials: "Watercolor on Paper",
        isForSale: false
      }
    },
    {
      image: require("./Blue Cart Provence.jpg"),
      details: {
        title: "Blue Cart Provence",
        materials: "Watercolor on Paper",
        size: "13 ins x 16 ins",
        isForSale: true
      }
    },
    {
      image: require("./Demi Tasse and Two Wee Spoons.jpeg"),
      details: {
        title: "Demi Tasse and Two Wee Spoons",
        materials: "Oil on Canvas",
        size: "7 in x 5 in",
        isForSale: true
      }
    },
    {
      image: require("./Figure Study.jpg"),
      details: {
        title: "Figure Study",
        materials: "Watercolor on Paper",
        size: "15 in x 10.5 in",
        isForSale: true
      }
    },
    {
      image: require("./Fruitstall Jeddah.jpeg"),
      details: {
        title: "Fruitstall Jeddah",
        materials: "Watercolor on Paper",
        size: "21 in x 19.5 in",
        isForSale: true
      }
    },
    {
      image: require("./Jeddah Shutter.jpg"),
      details: {
        title: "Jeddah Shutter",
        materials: "Watercolor on Paper",
        size: "16 in x 15 in",
        isForSale: true
      }
    },
    {
      image: require("./La Mesquita Cordoba.jpg"),
      details: {
        title: "La Mesquita Cordoba",
        materials: "Watercolor on Paper",
        size: "20 in x 17 in",
        isForSale: true
      }
    },
    {
      image: require("./Laid Back.jpg"),
      details: {
        title: "Laid Back",
        materials: "Oil on Canvas",
        size: "11 in x 8.75 in",
        isForSale: true
      }
    },
    {
      image: require("./Market Day Arabia.jpg"),
      details: {
        title: "Market Day Arabia",
        materials: "Watercolor on Paper",
        size: "20 in x 14 in",
        isForSale: true
      }
    },
    {
      image: require("./Moorish Traces in Granada.jpg"),
      details: {
        title: "Moorish Traces in Granada",
        materials: "Watercolor on Paper",
        size: "18.5 ins x 15.75 ins",
        isForSale: true
      }
    },
    {
      image: require("./My Cannas.jpg"),
      details: {
        title: "My Cannas",
        materials: "Watercolor on Paper",
        size: "18.75 in x 18.5 in",
        isForSale: true
      }
    },
    {
      image: require("./Naseef Roof.jpg"),
      details: {
        title: "Naseef Roof",
        materials: "Watercolor on Paper",
        size: "20 in x 15.5 in",
        isForSale: true
      }
    },
    {
      image: require("./Palms and Shutters.jpg"),
      details: {
        title: "Palms and Shutters",
        materials: "Watercolor on Paper",
        size: "12.5 in x 15.5 in",
        isForSale: true
      }
    },
    {
      image: require("./New Beginnings.jpg"),
      details: {
        title: "New Beginnings",
        materials: "Watercolor on Paper",
        size: "19.5 in x 14.75 in",
        isForSale: true
      }
    },
    {
      image: require("./Pansies on the porch.jpg"),
      details: {
        title: "Pansies on the porch",
        materials: "Oil on Canvas",
        size: "8.5 in x 11 in",
        isForSale: true
      }
    },
    {
      image: require("./Rainrops on rose.jpeg"),
      details: {
        title: "Rainrops on rose",
        materials: "Oil on Canvas",
        size: "8 in x 9.5 in",
        isForSale: true
      }
    },
    {
      image: require("./Santa Semana Malaga.jpg"),
      details: {
        title: "Santa Semana Malaga",
        materials: "Watercolor on Paper",
        size: "11.25 in x 14.25 in",
        isForSale: true
      }
    },
    {
      image: require("./Three Cups Tall.jpg"),
      details: {
        title: "Three Cups Tall",
        materials: "Oil on Canvas",
        size: "7 in x 9 in",
        isForSale: true
      }
    },
    {
      image: require("./Ubeda.jpg"),
      details: {
        title: "Ubeda",
        materials: "Watercolor on Paper",
        size: "15 in x 11 in",
        isForSale: true
      }
    },
    {
      image: require("./Wheels within Wheels.jpg"),
      details: {
        title: "Wheels within Wheels",
        materials: "Watercolor on Paper",
        size: "18.5 in x 18.5 in",
        isForSale: true
      }
    }
  ],
  artistPhoto: require("./Me 2020.jpeg"),
  bio: `I used to paint murals in London and Saudi Arabia and held workshops on Trompe L'oeil painting and on fantasy and antiquing effects. I also taught drawing and painting at Darat Binzagr in Jeddah. \n \n My work is a celebration of what I find beautiful, exotic and interesting. It is a visual response to the light and how it changes the simplest and often mundane objects into fascinating forms. Without light there is no life and no joy, and that is what I paint. I want people to feel that uplifting joy and to see and appreciate what we all have in common, a need to be uplifted.`
};
