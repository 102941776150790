export const angela_franco_works = {
        name: "Angela M Franco",
        workPromo: {
          image: require("./broken life.jpg"),
          heroDetails: {
            name: "Angela M Franco",
            title: "Broken Life",
            materials: "oil on canvas-board",
          }
        },
        link: '/Angela_M_Franco',
        works: [
          {
            image: require("./touched by love.jpg"),
            details: {
              title: "Touched by love",
              materials: "watercolor",
              size: '14" x 10"',
              isForSale: true
            }
          },
          {
            image: require("./silence .jpg"),
            details: {
              title: "Silence",
              materials: "oil on canvas-board",
              size: '20" x 16"',
              isForSale: true
            }
          },
          {
            image: require("./cold.jpg"),
            details: {
              title: "Cold",
              materials: "oil and pigments on canvas-board",
              size: '14" x 12"',
              isForSale: true
            }
          },
          {
            image: require("./Dressed in Yellow.jpg"),
            details: {
              title: "Dressed in Yellow",
              materials: "radio oil on canvas",
              isForSale: true
            }
          },
          {
            image: require("./broken life.jpg"),
            details: {
              title: "Broken Life",
              materials: "oil on canvas-board",
              size: '20" x 16"',
              isForSale: true
            }
          },
          {
            image: require("./Black Daisies .jpg"),
            details: {
              title: "Black Daisies",
              materials: "indian ink & fountain pens on paper",
              size: '22" x 20"',
              isForSale: true
            }
          },
          {
            image: require("./Dry Flowers.jpg"),
            details: {
              title: "Dry Flowers",
              materials: "canvas-board",
              size: '11" x 11"',
              isForSale: true
            }
          },
          {
            image: require("./20210103_211007.jpg"),
            details: {
              title: "The dance of the Sun and Soul",
              materials: "India ink, watercolors and fountain pen over Somerset paper",
              isForSale: false
            }
          },
          {
            image: require("./20210109_115037.jpg"),
            details: {
              title: "The Young Woman",
              materials: "Pencil colors over paper",
              isForSale: false
            }
          },
          {
            image: require("./20210111_151515.jpg"),
            details: {
              title: "Porcelain Boy",
              materials: "India ink, watercolors on paper",
              isForSale: false
            }
          },
          {
            image: require("./IMG_20210105_143025_155.jpg"),
            details: {
              title: "Snow over nude shoulders",
              materials: "India ink, watercolors and fountain pen over Somerset paper",
              isForSale: false
            }
          }
        ],
        artistPhoto: require("./franco_li.jpg"),
        bio: `From these past months, my work has traveled through deep and sensitive expressions, wanting to create a translucent variety of sensations in every other layer capable of captivating the peculiarity of the sounds of my soul, the smell of my days, and feelings of what life has brought upon me and surrounded with I am. \n \n I am looking to give to my work of art a voice to be listen in one's heart; and like a poem, the viewer will read across the space created, to then with freely intervals that textures and colors give away let the story of a life be upon one's eyes.`
}