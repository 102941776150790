export const toby_penney_works = {
  name: "Toby Penney",
  workPromo: {
    image: require("./Edge of Reason.jpeg"),
    heroDetails: {
      name: "Toby Penney",
      title: "Edge of Reason",
      materials: "Mixed media with reclaimed fabric and fibers on canvas"
    }
  },
  link: "Toby_Penney",
  works: [
    {
      image: require("./Sweet Thing.jpg"),
      details: {
        title: "Sweet Thing",
        materials: "Mixed media with reclaimed materials on canvas",
        size: "84 x 54 inches",
        isForSale: true
      }
    },
    {
      image: require("./Memories of Tomorrow.jpg"),
      details: {
        title: "Memories of Tomorrow",
        materials: "Mixed media with reclaimed fabric and fibers on canvas",
        size: "34 x 34 inches",
        isForSale: true
      }
    },
    {
      image: require("./Marked Path.jpg"),
      details: {
        title: "Marked Path",
        materials: "Mixed media with reclaimed materials on canvas",
        size: "44 x 54 inches",
        isForSale: true
      }
    },
    {
      image: require("./Life Expanded.jpg"),
      details: {
        title: "Life Expanded",
        materials: "Mixed media with reclaimed materials on canvas",
        size: "42 x 54 inches",
        isForSale: true
      }
    },
    {
      image: require("./Going Places.jpg"),
      details: {
        title: "Going Places",
        materials: "Mixed media with reclaimed materials on canvas",
        size: "90 x 54 inches",
        isForSale: true
      }
    },
    {
      image: require("./Finding Home Again.jpg"),
      details: {
        title: "Finding Home Again",
        materials: "Mixed media with reclaimed fabric and fibers on canvas",
        size: "74 x 54 inches",
        isForSale: true
      }
    },
    {
      image: require("./Eventide Blooms.jpg"),
      details: {
        title: "Eventide Blooms",
        materials: "Mixed media with reclaimed materials on canvas",
        size: "40 x 54 inches",
        isForSale: true
      }
    },
    {
      image: require("./Edge of Reason.jpeg"),
      details: {
        title: "Edge of Reason",
        materials: "Mixed media with reclaimed materials on canvas",
        size: "54 x 48 inches",
        isForSale: true
      }
    },
    {
      image: require("./Abundant Red.jpg"),
      details: {
        title: "Abundant Red",
        materials: "Mixed media with reclaimed material on canvas",
        size: "26 x 64 inches",
        isForSale: true
      }
    },
    {
      image: require("./Community Wash.jpg"),
      details: {
        title: "Community Wash",
        materials: "Mixed media with reclaimed fabric and fibers on canvas",
        size: "42 x 54 inches",
        isForSale: true
      }
    },
    {
      image: require("./Commotion.jpg"),
      details: {
        title: "Commotion",
        materials: "Mixed media with reclaimed materials on canvas",
        size: "42 x 30 inches",
        isForSale: true
      }
    },
    {
      image: require("./20200711_005425-02.jpeg"),
      details: {
        title: "Connecting The Past & Future",
        materials: "Mixed media with reclaimed fabric and fibers on canvas",
        size: "48 x 57 inches",
        isForSale: true
      }
    },
    {
      image: require("./20200711_192838-01-01.jpeg"),
      details: {
        title: "Words Echo In The Heart",
        materials: "Mixed media with reclaimed fabric and fibers on cardstock",
        size: "60 x 60 inches",
        isForSale: true
      }
    },
    {
      image: require("./20200812_160513-01.jpeg"),
      details: {
        title: "Talk, Talk, Talk",
        materials: "Mixed media with reclaimed fabric and fibers on cardstock",
        size: "60 x 60 inches",
        isForSale: true
      }
    },
    {
      image: require("./20200818_182304-01-01.jpeg"),
      details: {
        title: " I Dissent",
        materials: "Mixed media with reclaimed fabric and fibers on cardstock",
        size: "123 x 60 inches",
        isForSale: true
      }
    },
    {
      image: require("./20200819_160008-02.jpeg"),
      details: {
        title: "Wolf At The Door",
        materials: "Mixed media with reclaimed fabric and fibers on cardstock",
        size: "60 x 60 inches",
        isForSale: true
      }
    },
    {
      image: require("./20200829_151509-01.jpeg"),
      details: {
        title: " Choose Your Church Wisely",
        materials: "Mixed media with reclaimed fabric and fibers on cardstock",
        size: "96 x 60 inches",
        isForSale: true
      }
    },
    {
      image: require("./20200912_153253.jpg"),
      details: {
        title: "Clarity",
        materials: "Mixed media with reclaimed fabric and fibers on cardstock",
        size: "60 x 60 inches",
        isForSale: true
      }
    },
    {
      image: require("./20200919_163453-01.jpeg"),
      details: {
        title: "The Writing On The Wall",
        materials: "Mixed media with reclaimed fabric and fibers on cardstock",
        size: "123 x 60 inches",
        isForSale: true
      }
    },
    {
      image: require("./20201208_153822-02.jpeg"),
      details: {
        title: "What Now",
        materials: "Mixed media with reclaimed fabric and fibers on cardstock",
        size: "123 x 60 inches",
        isForSale: true
      }
    }
  ],
  artistPhoto: require("./20201215_112726-04.jpeg"),
  bio: `Toby Penney is a southern artist working in paint, photography, printmaking & multiple sculpture media. She creates work accepting, even glorifying simple objects & fleeting moments. Penney holds a sculpture degree from Middle Tennessee State University. \n \n From 2005 until 2010 she held a Vitreography internship with Master Printmaker Judith O’Rourke at Harvey K. Littleton Studios, in Western North Carolina. She photographed the process for the first Littleton sanctioned studio manual/ book about Vitreography. In the Fall of 2008 Toby was the guest artist in the printmaking department at Penland School of Craft, working with book/ paper artist Frank Brannon of Speak Easy Press. Usually you can find her sharing her studio with her enchanting 5 year old daughter. Find out more about Toby on social media via @TobyPenney.`
};
