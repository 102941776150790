export const joan_drescher_works = {
  name: "Joan Drescher",
  workPromo: {
    image: require("./Women Holding the Earth - LR.jpg"),
    heroDetails: {
      name: "Joan Drescher",
      title: "Women Holding the Earth"
    }
  },
  link: "/Joan_Drescher",
  works: [
    {
      image: require("./_Purple Majesty -  22_ x 25_.jpg"),
      details: {
        title: "Purple Majesty",
        materials: "Monotype/Collage",
        size: "22 in x 25 in",
        isForSale: true
      }
    },
    {
      image: require("./Awakening - 21_x 14 (1).jpg"),
      details: {
        title: "Awakening",
        materials: "Monotype/Collage",
        size: "21 in x 14 in",
        isForSale: true
      }
    },
    {
      image: require("./Believe in Peace - 29_ x 24_.jpg"),
      details: {
        title: "Believe in Peace",
        materials: "Monotype/Collage",
        size: "29 in x 24 in",
        isForSale: true
      }
    },
    {
      image: require("./Butterfly Joy - 15 x 15.jpg"),
      details: {
        title: "Butterfly Joy",
        materials: "Monotype/Collage",
        size: "15 in x 15 in",
        isForSale: true
      }
    },
    {
      image: require("./Eye of the Beholder - 22_ x 25_.jpg"),
      details: {
        title: "Eye of the Beholder",
        materials: "Monotype/Collage",
        size: "22 in x 25 in",
        isForSale: true
      }
    },
    {
      image: require("./Growing Inward - 26_ x 19_.jpg"),
      details: {
        title: "Growing Inward",
        materials: "Monotype/Collage",
        size: "26 in x 19 in",
        isForSale: true
      }
    },
    {
      image: require("./Hingham Roots - 29_ x 24.5_ .jpg"),
      details: {
        title: "Hingham Roots",
        materials: "Monotype/Collage",
        size: "29 in x 24.5 in",
        isForSale: true
      }
    },
    {
      image: require("./Letting Go - 17_ x 17_.jpg"),
      details: {
        title: "Letting Go",
        materials: "Monotype/Collage",
        size: "17 in x 17 in",
        isForSale: true
      }
    },
    {
      image: require("./Lighting the Path - 30_ x 35_.jpg"),
      details: {
        title: "Lighting the Path",
        materials: "Monotype/Collage",
        size: "30 in x 35 in",
        isForSale: true
      }
    },
    {
      image: require("./Magnetic Magenta - 12-3-20.jpg"),
      details: {
        title: "Magnetic Magenta",
        materials: "Monotype/Collage",
        size: "12 in x 20 in",
        isForSale: true
      }
    },
    {
      image: require("./Mariposa Tango - 38_ x 31_.jpg"),
      details: {
        title: "Mariposa Tango",
        materials: "Monotype/Collage",
        size: "38 in x 31 in",
        isForSale: true
      }
    },
    {
      image: require("./New Beginnings - 21_ x 14_.jpg"),
      details: {
        title: "New Beginnings",
        materials: "Monotype/Collage",
        size: "21 in x 14 in",
        isForSale: true
      }
    },
    {
      image: require("./One-sky-two-worlds.jpg"),
      details: {
        title: "One Sky Two Worlds",
        materials: "Monotype/Collage",
        size: "20 in x 20 in",
        isForSale: true
      }
    },
    {
      image: require("./Peace Messenger (1) - wh.jpg"),
      details: {
        title: "Peace Messenger",
        materials: "Monotype/Collage on Paper",
        size: "20 in x 20 in",
        isForSale: true
      }
    },
    {
      image: require("./Purple Passion - LR.jpeg"),
      details: {
        title: "Purple Majesty",
        materials: "Monotype/Collage",
        size: "21 in x 15 in",
        isForSale: true
      }
    },
    {
      image: require("./Quiet Heat - 20.5_ x 17.5_.jpg"),
      details: {
        title: "Quiet Heat",
        materials: "Monotype/Collage",
        size: "20.5 in x 17.5 in",
        isForSale: true
      }
    },
    {
      image: require("./Sacred Swim -  17_ x  17_.jpg"),
      details: {
        title: "Sacred Swim",
        materials: "Monotype/Collage",
        size: "17 in x 17 in",
        isForSale: true
      }
    },
    {
      image: require("./Sea Nest - 25_ x 24.5_.jpg"),
      details: {
        title: "Sea Nest",
        materials: "Monotype/Collage",
        size: "25 in x 24.5 in",
        isForSale: true
      }
    },
    {
      image: require("./Seeking the Path of Beauty - 26_ x 19_.jpg"),
      details: {
        title: "Seeking the Path of Beauty",
        materials: "Monotype/Collage",
        size: "26 in x 19 in",
        isForSale: true
      }
    },
    {
      image: require("./Sun Rising - 26_ x 21.5_.jpg"),
      details: {
        title: "Sun Rising",
        materials: "Monotype/Collage",
        size: "26 in x 21.5 in",
        isForSale: true
      }
    },
    {
      image: require("./Talking Flowers - 34_ x 27_.jpg"),
      details: {
        title: "Talking Flowers",
        materials: "Monotype",
        size: "34 in x 27 in",
        isForSale: true
      }
    },
    {
      image: require("./Vibration Orange - 26_ x 21_.jpg"),
      details: {
        title: "Vibration",
        materials: "Monotype/Collage",
        size: "26 in x 21 in",
        isForSale: true
      }
    },
    {
      image: require("./Women Holding the Earth - LR.jpg"),
      details: {
        title: "Women Holding the Earth",
        materials: "Monotype/Collage",
        size: "18 in x 24 in",
        isForSale: true
      }
    }
  ],
  artistPhoto: require("./Joan Drescher Photo.JPG"),
  bio: `My work as an artist has been to bring light into dark places, and to explore how art helps heal mind, body and spirit. I experience daily how color and images arouse the senses, opening us to a place deeper than words. \n \n Using torn, patterned and hand-colored paper, I create vivid monotype/collage prints and let the images speak to me. My interaction with these materials and the senses they awaken are like a conversation that leads to creating the final piece. This intuitive process has also resulted in paintings, published books and murals for healthcare.  \n \n I believe that art invokes magic, both in the creator and in those who receive it. The synergy between artist and viewer can make the invisible, visible.`
};
