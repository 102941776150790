export const raeda_ashour_works = {
  name: "Raeda Ashour",
  workPromo: {
    image: require("./RaedaAshour 72-1.jpg"),
    heroDetails: {
      name: "Raeda Ashour",
      title: "Untitled 1",
      materials: "Manual Embossing, water colors"
    }
  },
  link: "/Raeda_Ashour",
  works: [
    {
      image: require("./RaedaAshour 72-1.jpg"),
      details: {
        title: "Untitled 1",
        materials: "Manual Embossing, water colors",
        size: "100 x 70 cm",
        isForSale: true
      }
    },
    {
      image: require("./RaedaAshour 72-2.jpg"),
      details: {
        title: "Untitled 2",
        materials: "Manual Embossing, water colors, 24k. gold",
        size: "100 x 70 cm",
        isForSale: true
      }
    },
    {
      image: require("./RaedaAshour 72-3.jpg"),
      details: {
        title: "Untitled 3",
        materials: "Manual Embossing, water colors, 24k. gold",
        size: "100 x 70 cm",
        isForSale: true
      }
    },
    {
      image: require("./RaedaAshour 72-4.jpg"),
      details: {
        title: "Untitled 4",
        materials: "Manual Embossing, water colors, 24k. gold",
        size: "100 x 70 cm",
        isForSale: true
      }
    },
    {
      image: require("./RaedaAshour 72-5.jpg"),
      details: {
        title: "Mehrab Mosque in Cordoba",
        materials: "Manual Embossing on Paper, 22k gold",
        size: "100 x 70 cm",
        isForSale: true
      }
    },
    {
      image: require("./RaedaAshour 72-6.jpg"),
      details: {
        title: "Ottoman old tile",
        materials: "Manual Embossing on Paper, Watercolor, 22k gold",
        size: "76 × 56 cm",
        isForSale: true
      }
    },
    {
      image: require("./RaedaAshour 72-7.jpg"),
      details: {
        title: "Untitled 7",
        materials:
          "Manual Embossing on paper, water colors and soft pastels, 22K gold",
        size: "100 x 70 cm",
        isForSale: true
      }
    }
  ],
  artistPhoto: require("./IMG_9926enlarrged.jpg"),
  bio: `The journey in celebrating heritage is evident in all artworks produced by Raeda Ashour. The architectural elements and depicted detail reveal a strong and confident visualization and realization of aesthetic components prevalent in Islamic art and ornamentation. \n \n Raeda was inspired by old Arabian cities, with their famous architectural and aesthetic features. She never got tired of exploring and researching the subject of what constitutes Islamic art, its beauty, mystery, philosophy and aesthetics. She became passionate and obsessed by the beauty of the area. The more she delved into the subject, the more she became engrossed in its details, and she felt more connected and proud of her own roots and heritage.`
};
