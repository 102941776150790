import React from "react";
import { morgan_mckenna_works } from "../../../artist_images/Morgan_McKenna/morgan_mckenna_works";
import styled from "styled-components";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { mediaBreakpoints } from "../../../media_breaks";

export const MorganMcKennaHome = () => {
  const homeDisplayImage =
    morgan_mckenna_works.works[
      Math.floor(Math.random() * morgan_mckenna_works.works.length)
    ].image;
  return (
    <>
      <ThumbnailFrame>
        <HomePageThumbnail src={homeDisplayImage} />
        <HomePagename>{morgan_mckenna_works.name}</HomePagename>
      </ThumbnailFrame>
    </>
  );
};

export const MorganMcKennaGallery = () => {

  const onClickArt = (e, artwork) => {
    setArtOpen(true);
    setDisplayWork(artwork);
  };

  const onClickArtist = (e) => {
    setInfoOpen(true);
  }

  const handleClose = () => {
    setArtOpen(false);
    setInfoOpen(false);
  };

  const style = {
    position: "absolute",
    top: "15%",
      left: {
        xs: "15%",
        lg: "25%"
      },
      bottom: {
        xs: "10%",
        lg: "10%"
      },
      transform: "translate(-10%, -10%)",
      height: {
        xs: 6/10,
        sm: 6/10,
        md: 7/10,
        lg: 8.5/10,
      },
      width: {
        xs: 300,
        sm: 600,
        md: 700,
        lg: 900
      },
      maxHeight: 1,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 2,
  };

  const [displayWork, setDisplayWork] = useState();
  const [artOpen, setArtOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <PageWrapper>
      <Galleria>
         <Cover>
          <ArtistShowcase onClick={e => onClickArtist(e)}>
            <ArtistPhoto src={morgan_mckenna_works.artistPhoto}/>
            <ArtistName>{morgan_mckenna_works.name}</ArtistName>
          </ArtistShowcase>
        </Cover>
        {morgan_mckenna_works.works.map((artwork, index) => (
          <Cover key={index}>
            <ArtistThumbnail
              src={artwork.image}
              alt={artwork.details.title + ", a work by Morgan McKenna"}
              onClick={e => onClickArt(e, artwork)}
            />
          </Cover>
        ))}
        <Modal
          open={artOpen}
          onClose={handleClose}
          aria-labelledby="artwork"
          aria-describedby="a piece of artwork"
        >
          <Box sx={{ ...style}}>
            {displayWork && (
              <PopupWrapper>
                <ImageListItemBar
                  title={displayWork.details.title}
                  subtitle={`${morgan_mckenna_works.name}; ${
                    displayWork.details.size
                  }, ${displayWork.details.materials}`}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label={`sale status`}
                    >
                      {displayWork.details.isForSale ? (
                        <MonetizationOnIcon />
                      ) : (
                        <MoneyOffIcon />
                      )}
                    </IconButton>
                  }
                />
                <ArtistFullWork src={displayWork.image} />
              </PopupWrapper>
            )}
          </Box>
        </Modal>
        <Modal
          open={infoOpen}
          onClose={handleClose}
          aria-labelledby="artwork"
          aria-describedby="a piece of artwork"
        >
          <Box sx={{ ...style }}>
            <PopupWrapper>
              <ArtistPhoto modal src={morgan_mckenna_works.artistPhoto}/>
              <ArtistBio>{morgan_mckenna_works.bio}</ArtistBio>
            </PopupWrapper>
          </Box>
        </Modal>
      </Galleria>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  height: 99%;
  width: 100%;
  margin: 0;
  padding-top: 1vh;
  @media (max-width: ${mediaBreakpoints.mobile}){
    flex-direction: column;
  }
`;

const ArtistShowcase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: ${mediaBreakpoints.mobile}){
    width: 100%;
    height: 100%;
  }
`;

const ArtistPhoto = styled.img`
  height: ${props => props.modal ? '35vh' : '13vh'};
  width: 15vw;
  object-fit: cover;
  float: left;
  padding-right: ${props => props.modal ? '10px' : '0'};
  padding-bottom: ${props => props.modal ? '5px' : '0'};
  margin: ${props => props.modal ? '5px' : '0'};
  @media (max-width: ${mediaBreakpoints.tablet}) {
    width: ${props => props.modal ? '50%' : '100%'};
  }
`;

const ArtistName = styled.div`
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 0.25vh;
  padding-top: 0.25vh;
`;

const ArtistBio = styled.div`
  font-size: 18px;
  padding: 5px;
  line-height: 1.5;
  @media (max-width: ${mediaBreakpoints.laptop}) {
    font-size: 16px;
  }
`;

const Galleria = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media (max-width: ${mediaBreakpoints.mobileBig}){
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

const ThumbnailFrame = styled.div`
  margin: 5px;
`;

const Cover = styled.div`
  box-shadow: 10px 23px 20px 1px rgba(0,0,0,0.65);
  -webkit-box-shadow: 10px 23px 20px 1px rgba(0,0,0,0.65);
  -moz-box-shadow: 10px 23px 20px 1px rgba(0,0,0,0.65);
  height: 16vh;
  border: 5px solid black;
  @media (max-width: ${mediaBreakpoints.tablet}){
    height: 15vh;
  }
  @media (max-width: ${mediaBreakpoints.mobile}){
    width: 100%;
    height: 100%;
  }
`;

const HomePageThumbnail = styled.img`
  cursor: pointer;
  height: 22vh;
  width: 22vw;
  object-fit: cover;
  @media (max-width: ${mediaBreakpoints.mobile}){
    width: 45vw;
  }
`;

const HomePagename = styled.div`
  font-size: 20px;
  color: black;
  font-weight: 500;
`;

const ArtistThumbnail = styled.img`
  cursor: pointer;
  height: 16vh;
  width: 13vw;
  object-fit: cover;
  @media (max-width: ${mediaBreakpoints.tablet}){
    height: 15vh;
  }
  @media (max-width: ${mediaBreakpoints.mobile}){
    width: 100%;
  }
`;

const ArtistFullWork = styled.img`
  width: 100%;
  height: 600px;
  object-fit: cover;
  max-height: 75vh;
  @media (max-width: ${mediaBreakpoints.laptop}) {
    max-height: 60vh;
  }
  @media (max-width: ${mediaBreakpoints.tablet}) {
    max-height: 50vh;
  }
  @media (max-width: ${mediaBreakpoints.mobileBig}){
    max-height: 55vh;
  }
`;

const PopupWrapper = styled.div`
  max-height: 90%;
  @media (max-width: ${mediaBreakpoints.mobile}){
    height: 90%;
    width: 100%;
  }
`;
