export const cathie_crawford_works = {
  name: "Cathie Crawford",
  works: [
    {
      image: require("./Anima_Mundi_1440.jpg"),
      details: {
        title: "Anima Mundi, (soul of the world)",
        materials: "reduction woodcut",
        size: "20 in X 25 in",
        isForSale: true
      }
    },
    {
      image: require("./Breaking_1440.jpg"),
      details: {
        title: "Breaking",
        materials: "color reduction woodcut monoprint",
        size: "24 in X 36 in",
        isForSale: true
      }
    },
    {
      image: require("./Inception II 1440.jpg"),
      details: {
        title: "Inception II",
        materials: "color reduction woodcut monoprint",
        size: " 19 in X 24 in",
        isForSale: true
      }
    },
    {
      image: require("./Luna Sea 1440.jpg"),
      details: {
        title: "Luna Sea",
        materials: "color reduction woodcut",
        size: "24 in X 36 in,",
        isForSale: true
      }
    },
    {
      image: require("./Manzoku 1440.jpg"),
      details: {
        title: "Manzoku",
        materials: "Mokulito (lithography on wood)",
        size: "25 in X 22 in",
        isForSale: true
      }
    },
    {
      image: require("./Mystic 1440.jpg"),
      details: {
        title: "Mystic",
        materials: "color reduction woodcut",
        size: "6 in X 12 in",
        isForSale: true
      }
    },
    {
      image: require("./Nascent 1440.jpg"),
      details: {
        title: "Nascent",
        materials: "Mokulito (lithography on wood)",
        size: "20 in X 25 in",
        isForSale: true
      }
    },
    {
      image: require("./Noumena 1440.jpg"),
      details: {
        title: "Noumena",
        materials: "color reduction woodcut",
        size: "24 in X 36 in",
        isForSale: true
      }
    },
    {
      image: require("./Samsara III 1440.jpg"),
      details: {
        title: "Samsara III",
        materials: "color reduction woodcut",
        size: "6 in X 12 in",
        isForSale: true
      }
    },
    {
      image: require("./Serendipity 1440.jpg"),
      details: {
        title: "Serendipity",
        materials: "reduction woodcut",
        size: "19 in X 24 in",
        isForSale: true
      }
    },
    {
      image: require("./Shimmer 1440.jpg"),
      details: {
        title: "Shimmer",
        materials: "reduction woodcut",
        size: "6 in X 18 in bleed print",
        isForSale: true
      }
    },
    {
      image: require("./Spectrum 1440.jpg"),
      details: {
        title: "Spectrum",
        materials: "color reduction woodcut",
        size: "19 in X 24 in",
        isForSale: true
      }
    },
    {
      image: require("./Summer Daze 1440.jpg"),
      details: {
        title: "Summer Daze",
        materials: "reduction woodcut monoprint",
        size: "20 in X 25 in",
        isForSale: true
      }
    },
    {
      image: require("./Summer Soleil 1440.jpg"),
      details: {
        title: "Summer Soleil",
        materials: "reduction woodcut",
        size: "20 in X 25 in",
        isForSale: true
      }
    }
  ],
  artistPhoto: require("./Craig Stocks photo of me 1440.jpg"),
  bio: `Water theme images have been a constant source of inspiration throughout my printmaking career. I have also always been deeply enamored with the ever-changing hues of water, sky and land. I use color for its emotional impact. \n \n Taking a break from my figurative work, these reduction woodcuts are more non-objective. This new direction is the converging of color, line, shape, and texture in an ambiguous space with whispers of landscape.`
};
