export const gennaro_barci_works = {
  name: "Genarro Barci",
  workPromo: {
    image: require("./Quiet.jpg"),
    heroDetails: {
      name: "Genarro Barci",
      title: "Quiet",
      materials: "Enamels on Methacrylate"
    }
  },
  link: "/Gennaro_Barci",
  works: [
    {
      image: require("./A Fire Inside.jpg"),
      details: {
        title: "A Fire Inside (Un fuoco dentro)",
        materials:
          "Enamels on methacrylate, painted beforehand; powder coated steel",
        size: "(Height x Width x Depth): cm 215 x 50 x 40",
        isForSale: true
      }
    },
    {
      image: require("./acting energies.jpg"),
      details: {
        title: "Forze in atto (Acting Energies)",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 35 x 50 x 45",
        isForSale: true
      }
    },
    {
      image: require("./boogie-woogie6.jpg"),
      details: {
        title: "Boogie Woogie",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 47 x 43 x 20",
        isForSale: true
      }
    },
    {
      image: require("./Charon.jpg"),
      details: {
        title: "Caronte (Charon)",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 45 x 65 x 35",
        isForSale: true
      }
    },
    {
      image: require("./coercizione1.jpg"),
      details: {
        title: "Coercizione (Constraint)",
        materials: "Thermoformed methacrylate painted beforehand",
        size: "(Height x Width x Depth): cm 105 x 85 x 34",
        isForSale: true
      }
    },
    {
      image: require("./divenirenew.jpg"),
      details: {
        title: "Divenire (Becoming)",
        materials: "Enamels on Methacrylate",
        size: "Height x Width x Depth): cm 45 x 75 x 70",
        isForSale: true
      }
    },
    {
      image: require("./Dont Make Me Fall If You Cant Catch Me.jpg"),
      details: {
        title: "Don't Make Me Fall if You Can't Catch Me",
        materials: "Thermoformed Black Methacrylate",
        size: "Height x Width x Depth): cm 61 x 38 x 34",
        isForSale: true
      }
    },
    {
      image: require("./Entanglement.jpg"),
      details: {
        title: "Entanglement",
        materials: "Thermoformed Black Methacrylate",
        size: "(Height x Width x Depth): cm 57 x 25 x 25",
        isForSale: true
      }
    },
    {
      image: require("./Flight of the Red Heron.jpg"),
      details: {
        title: "Il volo dell'airone rosso (The Flight of the Red Heiron)",
        materials: "Enamels on methacrylate, steel",
        size: "(Height x Width x Depth): cm 133 x 35 x 40",
        isForSale: true
      }
    },
    {
      image: require("./il-tuo-profilo.jpg"),
      details: {
        title: "Il tuo profilo (Your Profile)",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 95 x 25 x 10",
        isForSale: true
      }
    },
    {
      image: require("./in-nomine-domini-4.jpg"),
      details: {
        title: "In nomine Domini",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 120 x 95 x 35",
        isForSale: true
      }
    },
    {
      image: require("./la-sublimazione-di-un-rombo.jpg"),
      details: {
        title: "La sublimazione di un rombo (Sublimation of a Rhombus)",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 100 x 56 x 31",
        isForSale: true
      }
    },
    {
      image: require("./Me Vs Me.jpg"),
      details: {
        title: "Me Vs Me",
        materials: "Thermoformed Black Methacrylate",
        size: "(Height x Width x Depth): cm 52 x 45 x 35",
        isForSale: true
      }
    },
    {
      image: require("./nuvola.jpg"),
      details: {
        title: "Nuvole #1 (Clouds #1)",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 60 x 82 x 28",
        isForSale: true
      }
    },
    {
      image: require("./nuvole2.jpg"),
      details: {
        title: "Nuvole #2 (Clouds #2)",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 65 x 92 x 28",
        isForSale: true
      }
    },
    {
      image: require("./Quiet.jpg"),
      details: {
        title: "Quiete (Quietness)",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 56 x 30 x 20",
        isForSale: true
      }
    },
    {
      image: require("./un-sentimento-inatteso.jpg"),
      details: {
        title: "Una sensazione inattesa (A Sudden Feeling)",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 150 x 65 x 12",
        isForSale: true
      }
    },
    {
      image: require("./Up To You.jpg"),
      details: {
        title: "L'incontro (Up to you)",
        materials: "Enamels on Methacrylate",
        size: "(Height x Width x Depth): cm 215 x 85 x 60",
        isForSale: true
      }
    }
  ],
  artistPhoto: require("./artist_img.jpg"),
  bio: `The artist started painting in 2006; after one year of tests, he decided to study the processes of interaction between colours: two enamels poured on an even support tend to blend autonomously. He realized that through this phenomenon, it was possible to depersonalise the product of the creative act and reach concepts that transcend reality. \n \n By employing a transparent material called “methacrylate”, the sculptor noticed that the colors blended on one side of the support. On its other side, instead, the first moment of the pictorial act remained imprinted, revealing the mark left by the colour before the process of depersonalisation. Hence, he decided to mould the painted support so that the two moments of metamorphosis could be shown and made available to the public.`
};
