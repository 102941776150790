export const susan_j_schrader_works = {
  name: "Susan j Schrader",
  workPromo: {
    image: require("./Nighttimekeeperlady750png.png"),
    heroDetails: {
      name: "Susan j Schrader",
      title: "Nighttime Keeper Lady - Lantern",
      materials: "Hand rolled aluminum/wire/paper on board"
    }
  },
  link: "/Susan_j_Schrader",
  works: [
    {
      image: require("./Nighttimekeeperlady750png.png"),
      details: {
        title: "Nighttime Keeper Lady - Lantern",
        materials: "Hand rolled aluminum/wire/paper on board",
        size: "33 in x 48 in",
        isForSale: true
      }
    },
    {
      image: require("./coppermoon750size.jpg"),
      details: {
        title: "Copper Moon",
        materials: "Hand rolled aluminum/ink/acrylic/copper/paper on board",
        size: "100 x 70 cm",
        isForSale: true
      }
    },
    {
      image: require("./Hope.png"),
      details: {
        title: "Hope",
        materials: "Ink/acrylic/paper on canvas/board",
        size: "33 in x 33 in",
        isForSale: true
      }
    },
    {
      image: require("./JazzyDance1750png.png"),
      details: {
        title: "Jazzy Dance #1",
        materials: "Ink/acrylic on board",
        size: "22 in x 22 in",
        isForSale: true
      }
    },
    {
      image: require("./jazzydancewithyou750png.png"),
      details: {
        title: "Jazzy Dance with You",
        materials: "Ink/acrylic/paper on board",
        size: "22 in x 22 in",
        isForSale: true
      }
    },
    {
      image: require("./Lakeflower750png.png"),
      details: {
        title: "Lake Flower",
        materials: "Ink/acrylic/resin on board",
        size: "28 in x 28 in",
        isForSale: true
      }
    },
    {
      image: require("./snazzywalkabout.png"),
      details: {
        title: "Snazzy Walkabout",
        materials: "Ink/acrylic on board",
        size: "38 in x 42 in",
        isForSale: true
      }
    }
  ],
  artistPhoto: require("./image0.jpeg"),
  bio:
    "Susan's work is filled with whimsical movements and fanciful creatures that dance across the surface of the canvas.  Her imaginative landscapes are full of colorful patterns and repetitive structures."
};
