import React from "react";
import styled from "styled-components";
import { mediaBreakpoints } from "../../media_breaks";

export const AboutPage = () => {
  return (
    <StyledPageWrapper>
      <StyledInfo>
        <h1>ABOUT SCHUSTER ART CONSULTANCY</h1>
        <p>
          Schuster Art Consultancy is an international art consultancy
          representing an exclusive and diverse portfolio of global artists.
        </p>

        <p>
          We represent award winning artists who excel in watercolors, acrylics,
          visual arts,nature photography, printmaking, plein air, sculpture,
          urban and heritage art, healing art, and geometric art.
        </p>

        <p>
          Schuster Art Consultancy has been active over 40 years in the
          international art and design profession. We offer our clients the
          combination of knowledge, experience, and professional guidance. We
          look forward to assisting you in future acquisitions and invite you to
          explore our world of creativity.
        </p>
      </StyledInfo>
    </StyledPageWrapper>
  );
};

const StyledPageWrapper = styled.div`
  display: flex;
  padding: 2vw;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledInfo = styled.div`
  width: 65%;
  font-size: 18px;
  text-align: center;
  height: 100%;
  line-height: 2;
  @media (max-width: ${mediaBreakpoints.tablet}){
    width: 85%;
    line-height: 1.5;
  }
  @media (max-width: ${mediaBreakpoints.mobile}){
    width: 100%;
    line-height: 1.5;
    font-size: 16px;
  }
`;
