import React from "react";
import styled from "styled-components";
import { mediaBreakpoints } from "../../media_breaks";

export const AppraiserPage = () => {
  return (
    <StyledPageWrapper>
      <StyledPicture>
        <AppraisalImage
          src={require("./Cecilia_Levin_MW_001.jpeg")}
          alt={"A picture of Cecilia Levin, art appraiser"}
        />
      </StyledPicture>
      <StyledInfo>
        <h1>ART ADVISORY SERVICES</h1>
        <p>
          Schuster Art Consultancy is pleased to announce the expansion of its services into the areas of Art Advisement and Evaluation, providing expertise and guidance to clients seeking to sell or donate works of art or collections as well as those interested in starting a collection or enriching their current holdings through new acquisitions- ones encouraged by the newest directions and trends of the art world. The Consultancy’s Evaluation Services range from the identification of art objects and in-depth analyses to monetary estimates and full appraisals, including those for insurance purposes. 
        </p>
        <p>
          All services are undertaken by our art adviser, Cecelia Levin, Ph.D.
          She obtained her doctorate in Art History from the Institute of Fine
          Arts at New York University: her specializations encompass the arts of
          Asia, as well as global contemporary art and American art of the 19th
          and early 20th centuries. Cecelia’s career has spanned teaching at
          several colleges and universities as well as holding curatorial and
          research positions in several major museums, including the
          Metropolitan Museum of Art, the Asian Art Museum of San Francisco, and
          the Museum of Fine Arts, Boston. Over the years, she has also served
          internationally as adviser to museums and cultural institutions on
          collection holdings, future acquisitions, and exhibition development.
        </p>
      </StyledInfo>
    </StyledPageWrapper>
  );
};

const StyledPageWrapper = styled.div`
  display: inline-flex;
  padding: 2vw;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: ${mediaBreakpoints.tablet}){
    display: block;
  }
`;

const AppraisalImage = styled.img`
  height: 70vh;
  @media (max-width: ${mediaBreakpoints.tablet}){
    height: 40vh;
  }
  @media (max-width: ${mediaBreakpoints.mobile}){
    height: 30vh;
  }
`;

const StyledPicture = styled.div`
  width: 45%;
  height: 50%;
  justify-content: center;
  @media (max-width: ${mediaBreakpoints.tablet}){
    width: 100%;
  }
`;

const StyledInfo = styled.div`
  width: 45%;
  font-size: 18px;
  text-align: left;
  @media (max-width: ${mediaBreakpoints.tablet}){
    width: 100%;
    text-align: center;
  }
`;
