import {
  AngelaFrancoGallery,
  AngelaFrancoHome
} from "./fe_components/artists_pages/angela_franco/angela_franco_landing";
import {
  CathieCrawfordHome,
  CathieCrawfordGallery
} from "./fe_components/artists_pages/cathie_crawford/cathie_crawford_landing";
import {
  DorothyBoyerHome,
  DorothyBoyerGallery
} from "./fe_components/artists_pages/dorothy_boyer/dorothy_boyer_landing";
import {
  GennaroBarciHome,
  GennaroBarciGallery
} from "./fe_components/artists_pages/gennaro_barci/gennaro_barci_landing";
import {
  JoanDrescherHome,
  JoanDrescherGallery
} from "./fe_components/artists_pages/joan_drescher/joan_drescher_landing";
import {
  LisaDeLongHome,
  LisaDeLongGallery
} from "./fe_components/artists_pages/lisa_delong/lisa_delong_landing";
import {
  MorganMcKennaGallery,
  MorganMcKennaHome
} from "./fe_components/artists_pages/morgan_mckenna/morgan_mckenna_landing";
import {
  RaedaAshourGallery,
  RaedaAshourHome
} from "./fe_components/artists_pages/raeda_ashour/raeda_ashour_landing";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import { AboutPage } from "./fe_components/about_page/about_page";
import { AppraiserPage } from "./fe_components/appraiser_page/appraiser_page";
import {
  SusanJSchraderGallery,
  SusanJSchraderHome
} from "./fe_components/artists_pages/susan_j_schrader/susan_j_schrader_landing";
import {
  TobyPenneyGallery,
  TobyPenneyHome
} from "./fe_components/artists_pages/toby_penney/toby_penney_landing";
import {
  TrudieCanwoodGallery,
  TrudieCanwoodHome
} from "./fe_components/artists_pages/trudie_canwood/trudie_canwood_landing";
import { mediaBreakpoints } from "./media_breaks";

const Header = () => {
  return (
    <StyledHeaderBackground>
      <StyledHeaderWithLinks>
        <div>schuster</div>
        <SubSplash>art consultancy</SubSplash>
      </StyledHeaderWithLinks>
      <StyledMenu>
        <StyledNavLinks to="/">Artists</StyledNavLinks>
        <StyledNavLinks to="/advisory-services">
          Advisory Services
        </StyledNavLinks>
        <StyledNavLinks to="/about">About</StyledNavLinks>
      </StyledMenu>
    </StyledHeaderBackground>
  );
};

const Footer = () => {
  return (
    <StyledFooterWrapper>
      <StyledFooterContainer>
        <p id="copyright-info">
          Copyright &copy; 2023 Schuster Art Consultancy
        </p>
      </StyledFooterContainer>
      <StyledFooterContainer>
        <StyledFooterLinks href="mStyledFooterLinksilto: info@schusterartconsultancy.com">
          info@schusterartconsultancy.com
        </StyledFooterLinks>
      </StyledFooterContainer>
      <StyledFooterContainer>
        <StyledFooterLinks
          href="https://www.facebook.com/pages/category/Local-Business/Schuster-Art-Consultancy-328435110650312/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="fa-icon" icon={faFacebook} size="2x" />
        </StyledFooterLinks>
        <StyledFooterLinks
          href="https://www.instagram.com/schuster_art_consultancy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="fa-icon" icon={faInstagram} size="2x" />
        </StyledFooterLinks>
        <StyledFooterLinks
          href="https://www.linkedin.com/in/susan-schuster-66aa9816/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="fa-icon" icon={faLinkedin} size="2x" />
        </StyledFooterLinks>
      </StyledFooterContainer>
    </StyledFooterWrapper>
  );
};

const MainBodyWrapper = () => {
  return (
    <RoutesWrapper>
      <StyledArtistThumbnailLink to="/angela_franco">
        <AngelaFrancoHome />
      </StyledArtistThumbnailLink>
      <StyledArtistThumbnailLink to="/cathie_crawford">
        <CathieCrawfordHome />
      </StyledArtistThumbnailLink>
      <StyledArtistThumbnailLink to="/dorothy_boyer">
        <DorothyBoyerHome />
      </StyledArtistThumbnailLink>
      <StyledArtistThumbnailLink to="/gennaro_barci">
        <GennaroBarciHome />
      </StyledArtistThumbnailLink>
      <StyledArtistThumbnailLink to="/joan_drescher">
        <JoanDrescherHome />
      </StyledArtistThumbnailLink>
      <StyledArtistThumbnailLink to="/lisa_delong">
        <LisaDeLongHome />
      </StyledArtistThumbnailLink>
      <StyledArtistThumbnailLink to="/morgan_mckenna">
        <MorganMcKennaHome />
      </StyledArtistThumbnailLink>
      <StyledArtistThumbnailLink to="/raeda_ashour">
        <RaedaAshourHome />
      </StyledArtistThumbnailLink>
      <StyledArtistThumbnailLink to="/susan_j_schrader">
        <SusanJSchraderHome />
      </StyledArtistThumbnailLink>
      <StyledArtistThumbnailLink to="/toby_penney">
        <TobyPenneyHome />
      </StyledArtistThumbnailLink>
      <StyledArtistThumbnailLink to="/trudie_canwood">
        <TrudieCanwoodHome />
      </StyledArtistThumbnailLink>
    </RoutesWrapper>
  );
};

export const App = () => {
  return (
    <LandingPage>
      <BrowserRouter>
        <Header />
        <MainBody>
          <Routes>
            <Route exact path="/" element={<MainBodyWrapper />} />
            <Route
              exact
              path="/advisory-services"
              element={<AppraiserPage />}
            />
            <Route path="/about" element={<AboutPage />} />
            <Route
              exact
              path="/angela_franco"
              element={<AngelaFrancoGallery />}
            />
            <Route
              exact
              path="/cathie_crawford"
              element={<CathieCrawfordGallery />}
            />
            <Route
              exact
              path="/dorothy_boyer"
              element={<DorothyBoyerGallery />}
            />
            <Route
              exact
              path="/gennaro_barci"
              element={<GennaroBarciGallery />}
            />
            <Route
              exact
              path="/joan_drescher"
              element={<JoanDrescherGallery />}
            />
            <Route exact path="/lisa_delong" element={<LisaDeLongGallery />} />
            <Route
              exact
              path="/morgan_mckenna"
              element={<MorganMcKennaGallery />}
            />
            <Route
              exact
              path="/raeda_ashour"
              element={<RaedaAshourGallery />}
            />
            <Route
              exact
              path="/susan_j_schrader"
              element={<SusanJSchraderGallery />}
            />
            <Route exact path="/toby_penney" element={<TobyPenneyGallery />} />
            <Route
              exact
              path="/trudie_canwood"
              element={<TrudieCanwoodGallery />}
            />
          </Routes>
        </MainBody>
        <Footer />
      </BrowserRouter>
    </LandingPage>
  );
};

const MainBody = styled.div`
  height: 85%;
  width: 100%;
  margin: 0;
  @media (max-width: ${mediaBreakpoints.mobile}){
    height: 100%;
  }
`;

const RoutesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
`;

const StyledArtistThumbnailLink = styled(Link)`
  text-decoration: none;
`;

const StyledFooterLinks = styled.a`
  text-decoration: none;
  color: white;
`;

const StyledFooterContainer = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 400;
  @media (max-width: ${mediaBreakpoints.tablet}){
    width: 100%;
    padding: 1vh;
  }
`;

const StyledFooterWrapper = styled.div`
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  height: 5%;
  margin: 0;
  padding: 2vh;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  @media (max-width: ${mediaBreakpoints.mobile}){
    height: 10%;
    flex-direction: column;
  }
`;

const StyledMenu = styled.ul`
  list-style-type: none;
  padding-left: 0;
  display: flex;
  justify-content: space-around;
  width: 75%;
  height: 6vh;
  font-size: 1.75em;
  font-weight: 400;
  margin-bottom: 2vh;
  align-items: center;
  @media (max-width: ${mediaBreakpoints.mobile}){
    width: 90%;
  }
`;

const SubSplash = styled.div`
  font-size: 20px;
  text-transform: uppercase;
`;

const StyledHeaderWithLinks = styled.header`
  font-family: "Helvetica", sans-serif;
  font-weight: 400;
  font-size: 45px;
  width: 25%;
  @media (max-width: ${mediaBreakpoints.tablet}){
    padding-top: 10px;
  }
  @media (max-width: ${mediaBreakpoints.mobile}){
    width: 100%;
    padding-top: 5px;
  }
`;

const StyledHeaderBackground = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  color: white;
  height: 10%;
  top: 0%;
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  align-items: center;
  @media (max-width: ${mediaBreakpoints.tablet}){
    flex-direction: column;
    height: 15%;
  }
  @media (max-width: ${mediaBreakpoints.mobile}){
    height: 19%;
  }
`;

const StyledNavLinks = styled(Link)`
  text-decoration: none;
  color: white;
  @media (max-width: ${mediaBreakpoints.mobile}){
    padding: 5px;
  }
`;

const LandingPage = styled.div`
  text-align: center;
  overflow: auto;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  @media (max-width: ${mediaBreakpoints.mobile}){
    height: 100%;
  }
`;

export default App;
